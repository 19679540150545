.info1-container h2{
    margin: 4rem 3rem;
    text-align: center;
    color: #eeba00;
    font-size: 1.5rem;
    
}

#contact{
    margin-top: 0.5rem;
    color: #fff;
    text-align: center;
    font-size: 1.3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

#contact-a{
    color: #1bf503;
    text-decoration: none;
    font-size: 1rem;
}

.ids{
    margin: 1rem 2rem;
}

.ids h3{
    font-size: 1.1rem;
    color: white;
}

.ids p{
    margin-bottom: 0.1rem;
}

.ids p a{
    color: #eeba00;
    text-decoration: none;
}

#bonus{
    margin-top: 2rem;
    margin-bottom: -0.2rem;
    color: #000000;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    padding-left: 2rem;
    padding-right: 2rem;
    background: #eeba00;
}

#panel{
    color: #eeba00;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0rem .5rem;
    padding-top: 5.5rem;
}

#type{
    color: #eeba00;
    text-align: center;
    font-size: 1.1rem;
}

#app{
   color: white;
   text-align: center;
   font-size: 1.2rem;
   padding: 0rem 3rem;
}