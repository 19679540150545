.header{
    width:100%;
    height: 120px;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    padding-top: 1.2rem;
}

.header h2{
    text-align: center;
    color: white;
    font-size: 1.8rem;
}

.header img {
    margin-top: .1rem;
  }