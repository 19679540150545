.apk{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.div{
    width: 85%;
    background-color: #fff;
    color: black;
    text-align: center;
    border-radius: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div1{
    display: flex;
    padding-top: .7rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
}

.div1 a{
    display: block;
    text-decoration: none;
    color: black;
}

.div1 p{
    font-size: .65rem;
}