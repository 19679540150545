.imgc{
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 180px;
  background-color: #000;
  display: flex;
  justify-content: end;
  align-items: end;
  padding: .3rem;
  border: 4px solid rgb(82, 80, 80);
  border-radius: 1rem;
}

.imgcon{
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.img{
  height: 320px;
  border-radius: .8rem;
}
