.info{
    width: 100%;
    margin-top: 1rem;
}

.info .para{
    color: #eeba00;
}

.info p{
    margin-top: 0.2rem;
    color: #fff;
    text-align: center;
    font-size: 1.3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.info p a{
    color: #1bf503;
    text-decoration: none;
    font-size: 1rem;
}

.detail{
    margin-top: 4rem;
    padding: .8rem .1rem;
    background: #2f2f2f;
}

.detail .head{
    color: #eeba00;
    font-size: 2rem;
}

.detail .body{
    color: white;
    font-size: 1.3rem;
}

#disclaimer{
    width: 100%;
    margin-top: 3rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: .7rem;
    font-size: .9rem;
    color: rgb(0, 0, 0);
    background: #c1c1c1;
}